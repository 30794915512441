.content {
  height: 100%;
}

.head {
  position: relative;
  width: 100%;
  min-height: 654px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 6%;
  background: url(../img/ic_dico.jpg) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.bg {
  position: absolute;
  z-index: -1;
  height: 100%;
}

.head_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .942rem .773rem 0;
}

.head_l {
  display: flex;
  align-items: center;
}

.head_l img {
  width: 1.159rem;
  height: 1.159rem;
  margin-right: .411rem;
}

.head_l span {
  color: #FFF;
  font-size: .58rem;
  flex-shrink: 0;
}

.head_r {
  width: .58rem;
  height: .58rem;
}

.head_title {
  color: #FFF;
  font-size: 1.546rem;
  display: block;
  text-align: center;
}

.head_intr {
  display: block;
  text-align: left;
  color: #FFF;
  font-size: .725rem;
  padding: 0 7.5%;
}

.con_bot {
  width: 100%;
  height: 462px;
  display: flex;
}

.con_bot_item {
  display: flex;
  height: 462px;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con_bot_item img {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.con_bot_item span {
  color: #FFF;
  font-size: .966rem;
}

@media only screen and (max-width: 828px) {
  .con_bot {
    flex-direction: column;
  }

  .con_bot_item {
    width: 100%;
  }

  .head_title {
    font-size: 32px;
  }

  .head_intr {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .con_bot_item span {
    font-size: 24px;
  }
}