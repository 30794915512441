.phWork {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  --bigFont: 0.6rem;
  --centreFont: 0.5rem;
  --littleFont: 0.27rem;
}

.phWorkTop {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.phWorkOne {
  letter-spacing: 0.01rem;
  color: #666666;
  width: 100%;
  font-size: var(--bigFont);
}

.phWorkTwo {
  letter-spacing: 0.01rem;
  color: #999999;
  width: 100%;
  font-size: var(--centreFont);
}

.phWorkThree {
  display: flex;
  position: relative;
  border-radius: 6%;
}

.workSwiperContaniner {
  height: 100%;
}

.line {
  margin: 0.26rem auto 0 auto;
  display: flex;
  justify-content: center;
}

.lineOne {
  width: 0.93rem;
  border: 0.026rem solid #ef605a;
  background: #ef605a;
}

.lineTwo {
  width: 0.4rem;
  border: 0.026rem solid #ef605a;
  margin-left: 0.1rem;
  background: #ef605a;
}

.lineThree {
  width: 0.26rem;
  border: 0.026rem solid #ef605a;
  margin-left: 0.1rem;
  background: #ef605a;
}

.phChoose {
  width: 100%;
  position: relative;
  --bigFont: 0.6rem;
  --centreFont: 0.5rem;
  --littleFont: 0.27rem;
}

.phOneChoose {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  white-space: nowrap;
}

.phChooseTextTwo {
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.phChooseVerticalOne {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #fff;
  position: absolute;
  font-size: var(--centreFont);
}

.verTextOne {
  word-break: break-all;
  display: flex;
  position: relative;
  width: 35%;
  font-size: var(--centreFont);
}

.verTextOneCircle {
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  position: absolute;
  animation: CircleLittle 20s linear infinite;
  top: 0%;
}

.verTextOneCircleLittle {
  width: 0.27rem;
  height: 0.27rem;
  left: 0.13rem;
  border-radius: 50%;
  position: absolute;
  background: #eee;
  top: 0%;
}

@keyframes CircleLittle {
  0% {transform: rotate(0deg);opacity: 1;}
  25% {transform: rotate(360deg);opacity: 1;}
  26% {opacity: 0;}
  100% {
    opacity: 0;
  }
}

@keyframes CircleLittleTwo {
  0% {opacity: 0;}
  25% {transform: rotate(0deg);opacity: 0;}
  26% {opacity: 1;}
  50% {transform: rotate(360deg);opacity: 1;}
  51% {opacity: 0;}
  100% {opacity: 0;}
}

@keyframes CircleLittleThree {
  0% {opacity: 0;}
  50% {opacity: 0;}
  51% {opacity: 1;transform: rotate(0deg);}
  73% {transform: rotate(360deg);opacity: 1;}
  74% {opacity: 0;}
  100% {opacity: 0;}
}

@keyframes CircleLittleFour {
  0% {opacity: 0;}
  74% {opacity: 0;}
  75% {opacity: 1;transform: rotate(0deg);}
  100% {opacity: 1;transform: rotate(360deg);}
}

.verTextTwo {
  word-break: break-all;
  direction: rtl;
  display: flex;
  width: 35%;
  position: relative;
}

.verTextTwoCircle {
  position: absolute;
  top: 0;
  animation: CircleLittleTwo 20s linear infinite;
}

.verTextTwoCircleLittle {
  width: 0.27rem;
  height: 0.27rem;
  left: 0.13rem;
  background: #eee;
  border-radius: 50%;
  position: absolute;
}

.phChooseVerticalTwo {
  display: flex;
  justify-content: space-around;
  color: #fff;
  width: 100%;
  font-size: var(--centreFont);
  position: absolute;
}

.phChooseTwoVertOne {
  word-break: break-all;
  display: flex;
  width: 35%;
  position: relative;
}

.phChooseTwoVertOneCircle {
  position: absolute;
  top: 0;
  animation: CircleLittleThree 20s linear infinite;
}

.phChooseTwoVertOneCircleLittle {
  position: absolute;
  width: 0.27rem;
  height: 0.27rem;
  left: 0.13rem;
  border-radius: 50%;
  background: #eee;
}

.phChooseTwoVertTwo {
  word-break: break-all;
  direction: rtl;
  display: flex;
  width: 35%;
  position: relative;
}

.phChooseTwoVertTwoCircle {
  position: absolute;
  top: 0;
  animation: CircleLittleFour 20s linear infinite;
}

.phChooseTwoVertTwoCircleLittle {
  position: absolute;
  width: 0.27rem;
  height: 0.27rem;
  left: 0.13rem;
  border-radius: 50%;
  background: #eee;
}

.phChooseModels {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -10%);
}

.phTwoChoose {
  position: absolute;
}

.phCanvasTwo {
  width: 100%;
  z-index: -2;
}

.aiPhone,
.individuality,
.fit,
.living_home {
  font-size: var(--littleFont);
  letter-spacing: 0.026rem;
}

.aiPhone {
  opacity: 0;
  animation: aiPhone 20s both infinite;
}

.aiPhone span {
  animation: aiPhone 20s var(--s) both infinite;
}

@keyframes aiPhone {
  0% {opacity: 1;}
  25% {opacity: 1;}
  30% {opacity: 0;}
  100% {opacity: 0;}
}

.fit {
  opacity: 0;
  animation: fit 20s both infinite;
}

.fit span {
  animation: fit 20s var(--s) both infinite;
}

@keyframes fit {
  0% {opacity: 0;}
  25% {opacity: 0;}
  26% {opacity: 1;}
  50% {opacity: 1;}
  55% {opacity: 0;}
  75% {opacity: 0;}
  100% {opacity: 0;}
}

.living_home {
  opacity: 0;
  animation: living_home 20s both infinite;
}

.living_home span {
  animation: living_home 20s var(--s) both infinite;
}

@keyframes living_home {
  0% {opacity: 0;}
  50% {opacity: 0;}
  51% {opacity: 1;}
  75% {opacity: 1;}
  80% {opacity: 0;}
  100% {opacity: 0;}
}

.individuality {
  opacity: 0;
  animation: individuality 20s both infinite;
}

.individuality span {
  animation: individuality 20s var(--s) both infinite;
}

@keyframes individuality {
  6% {opacity: 0;}
  50% {opacity: 0;}
  51% {opacity: 0;}
  73% {opacity: 0;}
  74% {opacity: 1;}
  100% { opacity: 1;}
}

.WorkPhoneContentOne {
  background-image: linear-gradient(rgba(234, 114, 165), rgba(184, 79, 92));
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-44.5%,-46.7%);
}

.phPhoneLeft {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
}

.phPhoneRight {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 99;
}

.workCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -3;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1px;
}

.modal img {
  width: 3rem;
}

.modalAndroid {
  display: flex;
  justify-content: flex-end;
}

.modalText {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
}

.phBannerBottomButton {
  position: absolute;
  left: 50%;
  top: 95.5%;
  transform: translate(-50%, 0);
  z-index: 999;
  animation: navagation 6s both infinite;
}

@keyframes navagation {
  0% {top: 95.5%;}
  50% {top: 97.5%;}
  100% {top: 95.5%;}
}

.phBannerBottomButtonImg {
  transform: rotateX(180deg);
}
