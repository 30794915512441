*{
    margin: 0;
    padding: 0;
}

a{
    color:#fff;
}

.mb-root-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #1a237e;
    overflow-x: hidden;
}

.mb-bgimg-ver {
    position: absolute;
    width:100%;
    height:100%;
}

.mb-invitation-text {
    position: absolute;
    letter-spacing: 0.2rem;
    color: rgba(186, 26, 253, 0.25);
    font-weight: 500;
    top: 15%;
    right: 0;
    font-size: 2.5rem;
    writing-mode: vertical-rl;
}

.mb-title-layer {
    position: relative;
    top: 1.5rem;
    left: 0.5rem;
    color: #fff;
}

.mb-title-num {
    font-size: 1rem;
}

.mb-title-chinese {
    font-size: 0.5rem;
}

.mb-title-english {
    font-size: 0.4rem;
}

.slogan-date-layer {
    position: relative;
    top:15%;
    left: 0.5rem;
}

.mb-slogan-layer {
    position: relative;
    font-size: 0.9rem;
}

.mb-slogan-front {
    position: relative;
    color: rgba(186,26,253,.9);
}

.mb-slogan-behind {
    margin-top: -1.3rem;
    color: rgba(0,0,0,.7);
}

.mb-start-date {
    position: relative;
    top:15%;
    left: 3.2rem;
    padding: 0rem 0.13rem 0.05rem;
    font-size: 0.45rem;
    color: #fff;
    background-color: rgba(186, 26, 253, 100);
    width: 48vw;
}

.mb-start-text {
    position: relative;
    top: 15%;
    left: 6.0rem;
    font-size: 0.5rem;
    color: #fff;
}

.mb-hot-title {
    position: relative;
    top: 20%;
    left: 0.5rem;
    color: #ffffff;
    font-size: 1rem;
}

.mb-hot-content {
    position: relative;
    top: 20%;
    left: 0.5rem;
    font-size: 0.35rem;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
}

.mb-qrcode-layer {
    position: relative;
    display: flex;
    top: 25%;
    left: 0.5rem;
    color: #fff;
}

.mb-qrcode-img {
    position: relative;
    height: 16vh;
}

.mb-qrcode-text {
    font-size: 0.35rem;
    margin-left: 15px;
    bottom: 0%;
}

.mb-express-text {
    margin-top: 20px;
    font-size: 0.2rem;
    color:rgba(255,255,255,0.8);
}

.mb-footer-text {
    position: relative;
    top: 30%;
    left: 0.5rem;
    padding:0.5rem 0rem;
    color:rgba(255,255,255,0.8);
    font-size: 0.3rem;
}