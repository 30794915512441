.paste_bg {
  position: relative;
}

.bg {
  background: url(../img/img_bg.jpg) bottom;
  background-repeat: no-repeat;
  background-size: 100% 80%;
  height: 100%;
  position: relative;
  background-color: #F6F5F8
}

.paste_top {
  display: flex;
  justify-content: space-between;
  padding:0 1.32rem;
}

.paste_zh {
  font-size: 0.96rem;
  font-weight: 500;
  color: rgba(0, 0, 34, 1);
  padding: 1.49rem 0
}

.paste_top img {
  height: 2.05rem;
  width: 2.05rem;
}

.pendant {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: move 2s infinite;
}

@keyframes move {
  0% {
    transform-origin: center top;
    transform: rotateZ(6deg);
  }
  50% {
    transform-origin: center top;
    transform: rotateZ(-6deg);
  }
  100% {
    transform-origin: center top;
    transform: rotateZ(6deg);
  }
}

.paste_wire {
  height: 1.49rem;
  width: 1px;
  background: #6E6D80;
  display: block;
}

.paste_bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  padding-left: 1.2rem;
  overflow: hidden;
  height: 85%;
}

.paste_kh {
  width: 2.04rem;
  height: 2.08rem;
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.paste_drug {
  width: 2rem;
  height: 2.3rem;
  position: absolute;
  bottom: 0;
  left: 30%;
}

.paste_jiu {
  width: 1.76rem;
  height: 3.27rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.paste_phone {
  width: 7.73rem;
  height: 11.16rem;
  margin: 0 auto;
  margin-bottom: -0.5rem;
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
    transform-origin: center bottom;
    transform: rotateZ(6deg);
  }
  50% {
    transform-origin: center bottom;
    transform: rotateZ(-6deg);
  }
  100% {
    transform-origin: center bottom;
    transform: rotateZ(6deg);
  }
}