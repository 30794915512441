* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
}

dl,
dt,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
form,
a,
p,
div,
textarea {
  margin: 0;
  padding: 0;
  font-family: 87.5%/1.8 "Microsoft Yahei", Arial, sans-serif;
}

a,
a:hover {
  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
}

button,
input {
  outline: none;
}

.main {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  overflow: hidden;
}

.blockback_zh {
  font-size: 1.23rem;
  font-weight: 500;
  color: rgba(245, 245, 245, 1);
}

.blockback_en {
  font-size: 0.64rem;
  font-weight: 400;
  color: rgba(245, 245, 245, 1);
}

.navigation-circle {
  display: block;
  position: relative;
  height: 320px;
  width: 320px;
  margin: auto;
}

.navigation-circle__inner {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.navigation-circle__list {
  display: block;
  position: absolute;
  height: 320px;
  width: 320px;
  transform: rotate(-60deg);
  animation: 2.2s cubic-bezier(0.25, -0.25, 0.35, 1) 0 1 animate-in-list
    forwards;
}

.navigation-circle-list-item:nth-of-type(1)
  .navigation-circle-list-item__point {
  background-color: #eb8b39;
  text-align: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0s 1
    animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(2)
  .navigation-circle-list-item__point {
  background-color: #cb484d;
  border-radius: 50%;
  text-align: center;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.2s 1
    animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(3)
  .navigation-circle-list-item__point {
  background-color: #60d5e0;
  border-radius: 50%;
  text-align: center;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.4s 1
    animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(4)
  .navigation-circle-list-item__point {
  background-color: #ffdfb8;
  border-radius: 50%;
  text-align: center;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.6s 1
    animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(5)
  .navigation-circle-list-item__point {
  background-color: #ac2fff;
  border-radius: 50%;
  text-align: center;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.8s 1
    animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(6)
  .navigation-circle-list-item__point {
  background-color: #9fd055;
  border-radius: 50%;
  text-align: center;
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 1s 1
    animate-in-list-item-point forwards;
}

.conimg {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -35%;
  margin-top: -35%;
  border: 10px solid #1f1e40;
}

.navigation-circle-list-item__point:before {
  content: "•";
  color: #020024;
}

.navigation-circle-list-item:nth-of-type(1)
  .navigation-circle-list-item__point:after {
  background-color: #eb8b39;
}

.navigation-circle-list-item:nth-of-type(2)
  .navigation-circle-list-item__point:after {
  background-color: #cb484d;
}

.navigation-circle-list-item:nth-of-type(3)
  .navigation-circle-list-item__point:after {
  background-color: #60d5e0;
}

.navigation-circle-list-item:nth-of-type(4)
  .navigation-circle-list-item__point:after {
  background-color: #ffdfb8;
}

.navigation-circle-list-item:nth-of-type(5)
  .navigation-circle-list-item__point:after {
  background-color: #ac2fff;
}

.navigation-circle-list-item:nth-of-type(6)
  .navigation-circle-list-item__point:after {
  background-color: #9fd055;
}

.navigation-circle-svg {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotateZ(-60deg);
}

.navigation-circle-svg--opaque {
  opacity: 0.5;
}

.navigation-circle-svg--mask circle {
  transition: all 0.5s ease;
  transition-delay: 0.5s;
  animation: 2.2s ease 0 1 animate-in-svg-circle-mask backwards;
}

.navigation-circle-list-item {
  display: block;
  position: absolute;
  height: 0;
  width: calc(50% + 16px);
  top: 50%;
  left: 50%;
  list-style: none;
  transform-origin: 0 0;
}

.navigation-circle-list-item__point {
  display: block;
  position: absolute;
  height: 20px;
  width: 20px;
  top: -8px;
  right: 8px;
  cursor: pointer;
  transform: scale(0);
}

.navigation-circle-list-item__point:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 0px;
  top: 9px;
  left: 31px;

  transition: all 0.5s ease;
}

.navigation-circle-list-item__meta {
  display: block;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transform-origin: center;
  margin-left: 78px;
  min-width: 182px;
  padding: 4px;
}

.navigation-circle-list-item__title {
  display: block;
  text-align: left;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 4px;
  margin-bottom: 6px;
  font-size: 0.4rem;
  font-weight: 400;
  color: rgba(245, 245, 245, 1);
}

.navigation-circle-list-item__subtitle {
  display: block;
  text-align: left;
  font-size: 0.32rem;
  font-weight: 200;
  color: rgba(245, 245, 245, 1);
}

.navigation-circle-list-item:nth-of-type(1) {
  transform: rotateZ(calc((360deg / 6) * 1));
}

.navigation-circle-list-item:nth-of-type(1) .navigation-circle-list-item__meta {
  margin-top: -37px;
  text-align: left;
  min-width: 260px;
}


.navigation-circle-list-item:nth-of-type(1)::after {
  background-color: red;
}

.navigation-circle-list-item:nth-of-type(2) {
  transform: rotateZ(calc((360deg / 6) * 2));
}

.navigation-circle-list-item:nth-of-type(2) .navigation-circle-list-item__meta {
  transform: rotateZ(-60deg);
  margin-left: 20px;
  margin-top: -180px;
  min-width: 260px;
  text-align: left;
}

.navigation-circle-list-item:nth-of-type(3) {
  transform: rotateZ(calc((360deg / 6) * 3));
}

.navigation-circle-list-item:nth-of-type(3) .navigation-circle-list-item__meta {
  transform: rotateZ(-120deg);
  margin-top: 70px;
  margin-left: 21px;
  text-align: left;
  min-width: 260px;
}

.navigation-circle-list-item:nth-of-type(4) {
  transform: rotateZ(calc((360deg / 6) * 4));
}

.navigation-circle-list-item:nth-of-type(4) .navigation-circle-list-item__meta {
  transform: rotateZ(-180deg);
  margin-top: -82px;
  margin-left: 77px;
  text-align: left;
  min-width: 260px;
}

.navigation-circle-list-item:nth-of-type(5) {
  transform: rotateZ(calc((360deg / 6) * 5));
}

.navigation-circle-list-item:nth-of-type(5) .navigation-circle-list-item__meta {
  transform: rotateZ(120deg);
  margin-top: -161px;
  margin-left: 20px;
  text-align: left;
  min-width: 260px;
}

.navigation-circle-list-item:nth-of-type(6) {
  transform: rotateZ(calc((360deg / 6) * 6));
}

.navigation-circle-list-item:nth-of-type(6) .navigation-circle-list-item__meta {
  transform: rotateZ(60deg);
  margin-left: 27px;
  margin-top: 58px;
  text-align: left;
  min-width: 260px;
}

.navigation-circle-list-item:hover .navigation-circle-list-item__point:after {
  width: 30px;
  left: 34px;
}

.navigation-circle-list-item:hover .navigation-circle-list-item__meta {
  opacity: 1;
}

.navigation-circle-list-item:active .navigation-circle-list-item__point:after,
.navigation-circle-list-item.active .navigation-circle-list-item__point:after {
  width: 30px;
  left: 34px;
}

.navigation-circle-list-item:active .navigation-circle-list-item__meta,
.navigation-circle-list-item.active .navigation-circle-list-item__meta {
  opacity: 1;
}

@keyframes animate-in-list {
  0% {
    transform: rotate(-540deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes animate-in-svg-circle-mask {
  0% {
    stroke-dashoffset: 1005;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-in-list-item-point {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* ::-webkit-scrollbar {
  display: none;
} */

.ball {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.ball1 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}

.ball2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #000022;
  overflow: hidden;
}

.firefly_blue {
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: #000022;
  box-shadow: 0px 0px 17px 2px #000022;
  border-radius: 50%;
}

.firefly_red {
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 17px 2px #fff;
  border-radius: 50%;
}

#train-vs-test {
  position: absolute !important;
  right: 0;
  width: 70%;
  height: 80vh;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 0 !important;
  display: flex;
}


@media only screen and (max-width: 667px) {
  #train-vs-test {
    position: absolute !important;
    right: 0;
    width: 100%;
    height: 80vh;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    bottom: 0 !important;
    display: flex;
    justify-content: center;
  }
}