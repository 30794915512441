*{
    margin: 0;
    padding: 0;
    list-style: none;
}  

a{
    color: #fff;
}

.pc-root-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: url('../../../img/img_cws_bg_h.jpg') no-repeat;
    overflow: hidden;
    align-items: center;
    background-size:100% 100%;
    pointer-events:none；
}

.pc-invitation-text {
    font-size: 2.67rem;
    color: rgba(186,26,253,.9);
    letter-spacing: 1.135rem;
    font-weight: 1000;
    text-align: right;
    width: 80%;
    z-index: 2;
}

.pc-content-layer {
    width: 80%;
    max-width: 26rem;
    display: flex;
    justify-content: space-between;
}

.pc-left-layer {
    width: 60%;
    height: 70vh;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
}

.pc-title-layer {
    color:#fff;
    margin-bottom: 0;
}

.pc-title-num {
    font-size: 1rem;
}

.pc-title-chinese{
    font-size: 0.5rem;
}

.pc-title-english {
    font-size: 0.4rem;
}

.pc-slogan-layer {
    width: 100%;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.pc-slogan-front {
    color: rgba(186,26,253,.9);
    position: relative;
}

.pc-slogan-behind {
    margin-top: -1.5rem;
    margin-left: .3%;
    color: rgba(0,0,0,.7);
}

.date-copyright-layer {
    color: #fff;
    font-size: .4rem;
}

.pc-start-date {
    padding: 0 .1rem;
    background-color: rgba(186,26,253,.9);
    width: 3.7rem;
    text-align: center;
    margin-bottom: .2rem;
}

.pc-start-text{
    margin-bottom: 0.4rem;
}

.pc-copyright-text {
    width: 150%;
    font-size: .28rem;
    margin-bottom: 0;
}

.pc-express-text {
    position: absolute;
    top: 80%;
    left: 27%;
    line-height: 0.4rem;
    color: rgba(255, 255, 255, 0.8);
}

.pc-right-layer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 40%;
    height: 71vh;
    min-width: 8.3rem;
    justify-content: space-between;
    align-items: flex-end;
}

.pc-hot-title {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.pc-hot-content {
    font-size: 0.3rem;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.8);
}

.pc-qrcode-layer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.pc-qrcode-img {
    height: 16vh;
    vertical-align: middle;
    border-style: none;
}

.pc-qrcode-text {
    text-align: right;
    font-size: .3rem;
}
