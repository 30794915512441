.content2 {
  padding: 0 0.63rem;
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
  z-index: -1;
  justify-content: center;
}

.dt {
  display: flex;
  align-items: center;
  width: 40%;
}

.dt img {
  width: 100%;
  margin-top: -250px;
}

.dt_font {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.dt_zh {
  display: block;
  font-size: 102px;
  font-size: 1.36rem;
  font-weight: 500;
  white-space: nowrap;
  color: rgba(0, 1, 31, 1);
}

.dt_en {
  display: block;
  font-size: 0.61rem;
  font-weight: 400;
  white-space: nowrap;
  color: rgba(0, 1, 31, 1);
}
