body {
  user-select: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.wengold {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}

#pointer {
  background: transparent;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 7;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

#pointer svg {
  height: 100%;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: rotateLoop linear 0 infinite;
  transition: width 0 ease-out, height 0 ease-out;
}

#download .ant-popover-inner-content {
  padding: 3px !important;
}

#download .ant-popover-inner {
  border-radius: 5px !important;
}

.cooperate .ant-modal-body {
  padding: 20px 15px !important;
}

.cooperate .ant-modal-close-x {
  width: 36px !important;
  height: 36px !important;
  line-height: 0 !important;
  font-family: inherit;
}

.cooperate {
  width: 240px !important;
  margin-top: 14% !important;
}

.cooperate .ant-modal-content {
  border-radius: 5px !important;
}

.cooperate .ant-modal-header {
  padding: 10px 10px !important;
}

#contaniner .swiper-button-next:after {
  display: none !important;
}

#contaniner .swiper-button-prev:after {
  display: none !important;
}

.col-xs-12,
.col-md-12,
.col-md-6,
.col-md-7,
.col-md-5,
.col-md-2,
.col-sm-2,
.col-sm-7,
.col-sm-5 {
  padding: 0 !important;
}

.container-fluid {
  width: 100vw !important;
  /* height: 100vh !important; */
  padding: 0 !important;
  margin: 0 !important;
}

.row {
  margin: 0 !important;
}