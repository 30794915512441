.content_title_zh {
  display: block;
  font-size: 0.96rem;
  font-weight: 500;
  word-wrap: normal;
  color: rgba(0, 0, 34, 1);
  text-align: center;
  line-height: 2;
  padding-top: 0.6rem;
}

.content_title_en {
  display: block;
  font-size: 0.61rem;
  font-weight: 400;
  word-wrap: normal;
  color: rgba(0, 0, 34, 1);
  text-align: center;
  line-height: 2;
}

.starmap {
  height: 80%;
}
