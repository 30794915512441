.phBanner {
  width: 100%;
  position: relative;
}

.phBannerText {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phBannerTextFlex {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.phBannerText_One {
  width: 37%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phBannerText_One > img{
  height: 1.1rem;
}

.phBannerText_Two {
  letter-spacing: .02rem;
  color: #ffffff;
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.phBannerLeftButton {
  position: absolute;
  top: 55%;
  animation: leftGoRight 5s infinite both;
}

@keyframes leftGoRight{
  50%{
    transform: translate(-25px,0);
  }
  1000%{
    transform: translate(0,0);
  }
}

 .phBannerBottomButton {
  position: absolute;
  left: 50%;
  top: 95.5%;
  transform: translate(-50%,0);
  z-index: 999;
  animation: navagation 5s both infinite;
}

@keyframes navagation {
  0%{top: 95.5%;}
  50%{top: 97.5%;}
  100%{top: 95.5%;}
}

.phBannerBottomButtonImg {
  transform: rotateX(180deg);
}

.phBannerRightButton {
  position: absolute;
  top: 55%;
  animation: rightGoLeft 5s infinite both;
}

@keyframes rightGoLeft{
  50%{
    transform: translate(25px,0);
  }
  100%{
    transform: translate(0,0);
  }
}

.phBannerText_Three {
  letter-spacing: .04rem;
}

.phBannerText_Four {
  text-align: center;
  letter-spacing: .04rem;
  overflow: hidden;
  word-wrap: break-word;
  height: 100%;
}
 
@keyframes smoky {
  0%{opacity: 0;transform: scale3d(.3, .3, .3);}
  20%{transform: scale3d(1.1, 1.1, 1.1);}
  40%{transform: scale3d(.9, .9, .9);}
  60%{opacity: 1;transform: scale3d(1.03, 1.03, 1.03);}
  80%{transform: scale3d(.97, .97, .97);}
  100%{opacity: 1;transform: scale3d(1, 1, 1);}
}

.phBannerLanren {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-30%);
}

.phBannerCanvasOne {
  width: 100%;
  position: absolute;
  z-index: -2;
  height: 100%;
  overflow: hidden;
}

.phCanvasTwoOne {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0,-15%);
  z-index: -99;
  height: 100%;
  overflow: hidden;
}

.phCanvasTwoOne div {
  height: 100%;
}

.phCanvasTwoOne div:nth-child(1) {
  background-image: linear-gradient(rgba(255, 255, 255), rgba(244,244,244));
}

.phCanvasTwoOne div:nth-child(2) {
  background-image: linear-gradient(rgba(255,255,255), rgba(240,240,240));
}
.phCanvasTwoOne div:nth-child(3) {
  background-image: linear-gradient(rgba(255, 255, 255), rgba(230,230,230));
}

.phCanvasTwoOne div:nth-child(4) {
  background-image: linear-gradient(rgba(255, 255, 255), rgba(242,242,242));
}

.phCanvasTwoOne div:nth-child(5) {
  background-image: linear-gradient(rgba(255, 255, 255), rgba(238,238,238));
}

.phCanvasTwoOne div:nth-child(6) {
  background-image: linear-gradient(rgba(255, 255, 255), rgba(240,240,240));
}

.phBannerCanvasTwo {
  position: absolute;
  height: 85%;
  width: 100%;
  overflow: hidden;
  z-index: 99;
}

.phWork {
  width: 100%;
  height: 100%;
}

.phType {
  width: 100%;
  overflow: hidden;
  z-index: 99;
}

.phTypeTextOne {
  letter-spacing: .04rem;
  color: #666666;
  display: flex;
  justify-content: center;
  margin: 1.06rem auto 0 auto;
}

.phTypeTextTwo {
  letter-spacing:.026rem;
  color: #999999;
  display: flex;
  justify-content: center;
  margin: .26rem auto 0 auto;
}

.phCarouselAll {
  width: 100%;
  height: 9.87rem;
}

.phCarousel {
  display: flex;
}

.phCarouselImgOne {
  width: 50%;
}

.phCarouselImgTwo {
  width: 50%;
}

.phCarouselTop {
  height: 60%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.phCarouselBottom {
  height: 40%;
  width: 100%;
  display: flex;
  align-items: center;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: .8rem;
  width: 100%;
}

.navigation span {
  width: .26rem;
  height: .26rem;
  margin: 0 0 0 .53rem;
  transform: rotate(45deg);
  border-radius: 50%;
}

.phCarouselShowTop {
  width: 50%;
  height: 70%;
  border: .053rem solid #fd7c7c;
  margin: 0 .53rem;
}

.phCarouseImgTop {
  width: 130%;
  height: 80%;
  transform: translate(20%, 15%);
}

.phCarouselShowBottom {
  width: 50%;
  height: 75%;
  border: .053rem solid #fd7c7c;
  margin: 0 0 0 1.87rem;
}

.phCarouseImgBottom {
  width: 100%;
  height: 100%;
  border: .053rem solid #fff;
  transform: translate(-10%, -35%);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  width: 100%;
  height: 1.07rem;
  border: .026rem solid #000;
}

.phFriend {
  width: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  position: relative;
}

.phFriendImg{
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -99;
}

.phFriendText{
  text-align: center;
  letter-spacing: .026rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-top: 18%;
  font-size: .5rem;
  height: 100%;
}

.friendLeft {
  border: 1px solid #eee;
  width: 5%;
  height: .8rem;
  border-right: 0;
  animation: friendHeight 4s both infinite;
}

.friendRight {
  border: 1px solid #eee;
  width: 5%;
  height: .8rem;
  border-left: 0;
  animation: friendHeight 4s both infinite;
}

@keyframes friendHeight {
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

.phAddress {
  width: 100%;
  background: rgba(223, 82, 76);
  color: #ffffff;
}

.phSetting {
  width: 80%;
  margin: 0 auto;
  font-size: 1.053rem;
}

.phAddressTextOne {
  padding: .8rem 0 0 0;
  font-size: .32rem;
  line-height: .45rem;
  letter-spacing: 0.026rem;
}

.phAddressTextTwo {
  display: flex;
  justify-content: space-between;
}

.phAddressTextTwoShow div {
  letter-spacing: 0.026rem;
  margin: .13rem 0 0 0;
}

.phAddressTextThree {
  letter-spacing: 0.026rem;
}

.phAddressTextFour {
  letter-spacing: 0.026rem;
  margin: .13rem 0 0 .07rem;
  font-size: .32rem;
  display: flex;
}

.phAddressLine {
  opacity: 0.1;
  border: .026rem solid #ffffff;
  width: 100%;
  margin: .26rem 0 0 0;
}

.phAddressTextFive {
  line-height: 0.48rem;
  letter-spacing: 0.02;
  color: #ffffff;
  margin: .13rem 0 0 .13rem;
  font-size: 1.053rem;
  padding-bottom: 5%;
}

.line {
  width: 20%;
  margin: .26rem auto 0 auto;
  display: flex;
}

.lineOne {
  width: .93rem;
  border: .026rem solid #ef605a;
  background: #ef605a;
}

.lineTwo {
  width: .4rem;
  border: .026rem solid #ef605a;
  margin-left: .106rem;
  background: #ef605a;
}

.lineThree {
  width: .26rem;
  border: .026rem solid #ef605a;
  margin-left: .106rem;
  background: #ef605a;
}

.CarouselGif ul {
  width: 100%;
}

.Hand_logo {
  width: 25%;
}

.actionImg {
  position: absolute;
  width: .8rem;
  height: .8rem;
}

.phBannerText_Five {
  width: 100%;
  display: flex;
  height: 10%;
  position: relative;
}

.phContentFiveinnerCircle{
  height: .8rem;
  margin-left: 1.4rem;
}

.phContentFiveinnerCircle div {
  position: absolute;
  transition: opacity 2s;
  width: .8rem;
  height: .8rem;
  margin-left: -.4rem;
}

.imgModel {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%,-30%);
  border-radius: 10%;
}

.popoverShow {
  text-align: center;
}

.popoverShow img {
  width: 3rem;
}

.modalText {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
}

.modalAndroid {
  display: flex;
  justify-content: flex-end;
}

.error {
  width: 1rem;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%,0);
}