.content1 {
  width: 100%;
  position: relative;
}

.virtual {
  width: 100%;
  height: 100%;
}

.img_top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50%;
  overflow-y: hidden;
}

.img_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  overflow-y: hidden;
}

.add {
  top: 0;
  position: absolute;
  padding-top: 0.71rem;
  width: 100%;
}

.add_font {
  margin: 0 auto;
  height: 100%;
  padding-left: 0.47rem;
}

.font_virtual {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add_zh {
  display: block;
  font-size: 0.96rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 2;
}

.add_en {
  font-size: 0.61rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.add_img {
  height: 1.09rem;
  width: 1.09rem;
  margin-left: 0.32rem;
}

.bar {
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar img {
  height: 1.01rem;
  width: 1.01rem;
}
