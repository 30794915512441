img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.headLogo {
  display: flex;
  justify-content: space-between;
  width: 89%;
  height: 0.81rem;
  margin-top: 0.56rem;
  margin-left: auto;
  margin-right: auto;
}

.logoS {
  width: 0.78rem;
  height: 0.78rem;
  border-radius: 50%;
  text-align: center;
  line-height: 0.78rem;
}

.logoS img {
  width: 0.44rem;
  height: 0.44rem;
  object-fit: contain;
}

.logoRight {
  width: 0.78rem;
  height: 0.78rem;
  text-align: center;
  line-height: 0.78rem;
}

.logoRightImg {
  width: 0.37rem;
  height: 0.44rem;
}

.xsHead {
  font-weight: bold;
  color: #3D4A58;

}

.xsCards {
  display: flex;
  height: 10.56rem;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.cardsScroll {
  display: flex;
  white-space: nowrap;
}

.mainImg {
  overflow: hidden;

}

.mainImg img {
  border-radius: 0.22rem;
}

.mainImgFirst {
  position: relative;
  width: 85vw;
  height: 100%;
  border-radius: 0.22rem;
  margin-right: 6px;
}

.mainPlay {
  width: 0.96rem;
  height: 0.96rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 100;
}

.mainShow {
  position: absolute;
  top: 72%;
  left: 4%;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 0.59rem;
  z-index: 100;
}

.headMain {
  display: flex;
  width: 100%;
  height: 14rem;
}

.fixedScan {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.96rem;
  height: 0.96rem;
  z-index: 99;
}

.doSomething {
  width: 68vw;
  position: relative;
  text-align: center;
  margin-top: 1rem;
  margin-right: 6px;
  border: 1px solid #FF6660;
}

.doSomethingCan {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.thingsIcon {
  width: 0.96rem;
  height: 0.96rem;
  background-color: #fff;
  border-radius: 50%;
  margin: 0.74rem auto;
}

.doIcons {
  width: 0.45rem;
  height: 0.45rem;
  object-fit: contain;
  margin: 0.25rem;
}

.iconsTextBox {
  width: 80%;
  height: 60%;
  margin-top: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

.iconsHead {
  height: 0.39rem;
  text-align: center;
  line-height: 0.39rem;
  font-size: 0.44rem;
}

.iconsMain {
  height: 0.44rem;
  margin-top: 0.33rem;
  text-align: center;
  line-height: 0.44rem;
  font-size: 0.37rem;
}

.xsCanDO {
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  overflow-x: scroll;
}

.chooseMainHead {
  width: 100%;
  height: 25%;
}

.chooseMain {
  margin-top: 2.25rem;
  height: 10.28rem;
}

.chooseTextCn {
  height: 1.26rem;
  font-size: 0.96rem;
  line-height: 1.26rem;
  text-align: center;
}

.chooseTextEn {
  position: relative;
  width: 5rem;
  height: 0.7rem;
  font-size: 0.96rem;
  line-height: 0.7rem;
  color: #75797D;
  margin-top: 0.56rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.chooseTextEn:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.5rem;
  left: 0.7rem;
  width: 1.5rem;
  height: 0.056rem;
  background: #72B422;
}

.chooseReason {
  width: 100%;
  height: 40%;
  margin-top: 1.46rem;
}

.reasons {
  display: flex;
  width: 100%;
  height: 1.04rem;
  margin-bottom: 0.3rem;
}

.reasonsImg {
  width: 1.04rem;
  height: 1.04rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.reasonIcon {
  width: 0.39rem;
  height: 0.44rem;
  margin: 0.29rem;
  object-fit: contain;
}

.reasonsHead {
  line-height: 1.04rem;
  font-size: 0.44rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reasonsMain {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.04rem;
  font-size: 0.3rem;
  color: #808386;
}

.digital {
  position: absolute;
  top: -1.5vh;
  right: 0;
  width: 2.33rem;
  height: 1.11rem;
  background-color: #72B422;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Arial;
}

.digital p {
  padding: 0;
  margin: 0;
}

.human {
  position: relative;
  object-fit: cover;
}

.footer {
  width: 100%;
  height: 8.67rem;
}

.fotterImg {
  position: relative;
  height: 4.33rem;
}


.footCover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: linear-gradient(180deg, rgba(33, 42, 52, 0) 0%, #212A34 147.33%);
  opacity: 0.9;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.fotterButton {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 3.26rem;
  height: 2.5em;
  border-radius: 0.44rem;
  background-color: #FF6660;
  cursor: pointer;
  transform: translate(-50% -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}

.buttonBox {
  display: flex;
  width: 81%;
  height: 80%;
  margin: 0 auto;
}

.buttonTextCn {
  text-align: center;
  color: #fff;
  font-size: 0.37rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonTextEn {
  text-align: center;
  color: #FFD1CF;
  font-size: 0.29rem;
}

.contact {
  height: 6.56rem;
  background-color: #FF6660;
}

.contactBox {
  width: 89%;
  height: 42%;
  margin: 0 auto;
}

.details {
  display: flex;
  width: 80%;
  height: 100%;
}

.detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2.26rem;
  margin-top: 1.15rem;
}

.detailBox {
  display: flex;
  width: 100%;
  height: 0.41rem;
  margin-bottom: 0.48rem;
  text-align: center;
  line-height: 0.41rem;
}

.detailImg {
  width: 0.44rem;
  height: 0.37rem;
}

.detailImg img {
  object-fit: contain;
}

.detailText {
  opacity: 0.7;
  height: 0.41rem;
  text-align: center;
  line-height: 0.5rem;
  color: #fff;
  font-size: 0.3rem;
  margin-left: 0.22rem;
  white-space: nowrap;
}

.scanCom {
  position: absolute;
  bottom: 3.5rem;
  right: 2vw;
  width: 1.92rem;
  height: 1.92rem;
}

.address {
  width: 89%;
  height: 20%;
  margin: 25px auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.addressText {
  color: #fff;
  margin-top: 0.4rem;
  opacity: 0.7;
  font-size: 0.18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.IFSCBox {
  width: 5rem;
  height: 5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 150;
  border: 1px solid #EF605A;
  border-radius: 5px;
}

.grayCover {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 120;
}

::-webkit-scrollbar {
  width: 0;
}

.VideoShow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 200;
}

.VideoShow iframe {
  width: 100%;
  height: 100%;
}

.closeVideo {
  position: fixed;
  top: 15vh;
  left: 3vh;
  width: 1rem;
  height: 1rem;
  z-index: 220;
}

.closeVideo img {
  object-fit: contain;
}

.xsBlackCover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  background: linear-gradient(184.99deg, rgba(126, 126, 126, 0) 39.77%, #000000 113.75%);
}

a {
  text-decoration: none !important;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none !important;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none !important;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none !important;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none !important;
}

@media (max-width: 1920px) {
  .mainTitle {
    font-size: 1.83rem;
  }
}

@media (max-width: 1700px) {
  .mainTitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 1120px) {
  .mainTitle {
    font-size: 1rem;
  }

  .reasonsHead {
    font-size: 0.3rem;
  }

  .reasonsMain {
    font-size: 0.2rem;
  }

  .iconsHead {
    font-size: 0.34rem !important;
  }

  .iconsMain {
    font-size: 0.3rem !important;
  }
}

@media (max-width: 1000px) {
  .reasonsHead {
    font-size: 0.4rem;
  }

  .reasonsMain {
    font-size: 0.3rem;
  }
}

@media (max-width: 990px) {

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    background-color: #DCDCDC;
  }
}

@media (max-width: 400px) {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@media (min-width: 992px) {

  .VideoShow {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vh;
    z-index: 200;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logoS {
    display: flex;
    width: 2.37rem;
  }

  .logosImg {
    width: 0.41rem;
    height: 0.59rem;
    margin-right: 0.2rem;
  }

  .logoW {
    display: flex;
  }

  .logosText {
    width: 80%;
    font-size: 0.37rem;
    font-weight: 600;
    color: #EF605A;
  }

  .logowImg {
    margin-right: 6px;
    width: 0.46rem;
    height: 0.44rem;
  }

  .logowText {
    font-weight: 600;
    font-size: 0.37rem;
    color: #212A34;
  }

  .logowImgBack {
    width: 0.41rem;
    height: 0.23rem;
    margin-left: 0.2rem;
    margin-top: 0.1rem;
  }

  .headMain {
    justify-content: space-between;
    width: 89%;
    height: 9.62rem;
    margin-top: 2.06rem;
    margin-left: auto;
    margin-right: auto;
  }

  .headMainText {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .mainTitle {
    width: 100%;
    margin-top: 0.74rem;
    color: #212A34;
    height: 2rem;
    font-weight: bold;
    line-height: 1.83rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .IFSCCode {
    position: absolute;
    top: 70%;
    left: 18%;
    width: 2rem;
    height: 2rem;
    opacity: 0;
    border-radius: 5px;
    border: 1px solid #EF605A;
    text-align: center;
    line-height: 2rem;
  }

  .IFECIn {
    animation: fadeIn 1s linear 1 alternate forwards;
  }

  .IFECOut {
    animation: fadeOut 1s linear 1 alternate forwards;
  }

  .IFSCImg {
    width: 95%;
    height: 90%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .scan {
    width: 0.44rem;
    height: 0.44rem;
    object-fit: contain;
    margin-top: -9px;
  }

  .headMainImgs {
    display: flex;
    height: 8.67rem;
    overflow: hidden;
  }


  .mainImgFirst {
    width: 22%;
    margin-right: 0.37rem;
  }

  .mainImgSecond {
    position: relative;
    width: 56%;
    height: 100%;
    margin-right: 0.37rem;
    border-radius: 0.22rem;
    overflow: hidden;
  }

  .blackCover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: linear-gradient(184.99deg, rgba(126, 126, 126, 0) 39.77%, #000000 113.75%);
  }

  .mainShow {
    z-index: 100;
    font-size: 0.49rem;
  }

  .mainImgThird {
    position: relative;
    width: 22%;
    height: 100%;
    border-radius: 0.22rem;
  }

  .interflow {
    position: absolute;
    top: 82%;
    left: 0;
    width: 1.8rem;
    height: 1rem;
    background-color: #FF6660;
    color: #fff;
    text-align: center;
    line-height: 1rem;
    font-size: 0.44rem;
    z-index: 100;
  }

  @keyframes bigger {
    0% {
      width: 22%;
    }

    100% {
      width: 82%;
    }
  }

  @keyframes smaller {
    0% {
      width: 82%;
    }

    100% {
      width: 22%;
    }
  }

  .canDo {
    display: flex;
    justify-content: space-between;
    width: 89%;
    height: 8.03rem;
    margin-top: 2.78rem;
    margin-left: auto;
    margin-right: auto;
  }

  .doSomething {
    display: flex;
    flex-direction: column;
    height: 6.66rem;
    position: relative;
    border-radius: 0.22rem;
    text-align: left;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 0;
  }

  .doSomething:hover {
    border: 1px solid #FF6660;
  }

  .doSomethingCan {
    display: flex;
    flex-direction: column;
    width: 5.56rem !important;
    height: 6.66rem;
    text-align: left;
    margin-top: 0;
  }

  .doHead {
    margin-top: 0.55rem;
    white-space: nowrap;
  }

  .thingsIcon {
    left: 35%;
    width: 1.33rem;
    height: 1.33rem;
  }

  .doIcons {
    width: 0.63rem;
    height: 0.63rem;
    margin: 0.35rem;
  }

  .iconsHead {
    position: absolute;
    top: 40%;
    width: 100%;
    height: 0.54rem;
    text-align: center;
    line-height: 0.54rem;
    font-size: 0.44rem;
  }

  .iconsMain {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 0.44rem;
    margin-top: 0.33rem;
    text-align: center;
    line-height: 0.44rem;
    font-size: 0.37rem;
  }

  .chooseUS {
    display: flex;
    width: 89%;
    height: 14rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .digital {
    top: -4vh;
    width: 2.96rem;
    height: 1.48rem;
  }

  .digital p {
    padding-top: 5px;
  }

  .chooseMains {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.28rem;
  }

  .chooseMain {
    margin-top: 0;
    height: 100%;
  }

  .chooseTextCn {
    text-align: left;
  }

  .chooseTextEn {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    font-size: 0.96rem;
  }

  .chooseTextEn:before {
    bottom: -0.5rem;
    left: 0;
    width: 1.5rem;
  }

  .footer {
    height: 15.44rem;
  }

  .fotterImg {
    position: relative;
    height: 11.11rem;
  }

  .fotterButton {
    width: 4.44rem;
    height: 1.78rem;
    border-radius: 0;
  }

  .buttonBox {
    flex-direction: column;
  }

  .buttonTextCn {
    text-align: center;
    color: #fff;
    font-size: 0.67rem;
    margin: 0;
  }

  .buttonTextEn {
    text-align: center;
    color: #FFD1CF;
    font-size: 0.44rem;
    margin: 0;
  }

  .contact {
    height: 4.33rem;
  }

  .contactBox {
    display: flex;
    justify-content: space-between;
  }

  .detail {
    width: 33%;
  }

  .detailBox {
    line-height: 0.41rem;
  }

  .detailText {
    opacity: 1;
  }

  .scanCom {
    position: absolute;
    width: 2.41rem;
    height: 2.41rem;
    bottom: 1rem;
    right: 1rem;
  }

  .address {
    width: 89%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border: 0;
  }


  .addressText {
    width: 70%;
    height: 1rem;
    margin-top: 0;
    opacity: 1;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.26rem;
    overflow: visible;
    white-space: normal;
  }
}

.loading {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  text-align: center;
  line-height: 51px;
  margin: 0.2rem;
}

.loading:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  width: 57px;
  height: 57px;
  border: 1px solid transparent;
  border-top: 1px solid #FF6660;
  border-right: 1px solid #FF6660;
  border-radius: 50%;
  animation: animteCircle 2s linear infinite;
}

.dot {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.dot:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #FF6660;
  top: 0px;
  right: -2px;
}

.smloading:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -9px;
  width: 42px;
  height: 42px;
  border: 1px solid transparent;
  border-top: 1px solid #FF6660;
  border-right: 1px solid #FF6660;
  border-radius: 50%;
  animation: animteCircle 2s linear infinite;
}

.smdot {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.smdot:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #FF6660;
  top: -1px;
  right: -10px;
}

.smloading, .xsloading {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
}

.xsdot {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 45%;
  height: 4px;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.xsloading:before {
  content: "";
  position: absolute;
  top: 1px;
  left: -1px;
  width: 42px;
  height: 42px;
  border: 1px solid transparent;
  border-top: 1px solid #FF6660;
  border-right: 1px solid #FF6660;
  border-radius: 50%;
  animation: animteCircle 2s linear infinite;
}

.xsdot:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #FF6660;
  top: 0px;
  right: -3px;
}


@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@keyframes animteCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}