.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.theme {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.box_title {
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0, 0, 34, 1);
  display: block;
  text-align: center;
}

.box_content {
  background-color: #000022;
  height: 55%;
  position: absolute;
  width: 100%;
  top: 7%;
  z-index: -2;
}

.box_content_top {
  width: 100%;
  height: 35%;
  background: linear-gradient(to bottom left, #fff 50%, transparent 50%);
  position: absolute;
  right: 0;
  top: -1px;
}

.box_content_bottom {
  width: 100%;
  height: 35%;
  background: linear-gradient(to top left, #fff 50%, transparent 50%);
  position: absolute;
  right: 0;
  bottom: -1px;
}

.box_img {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.box_trianglecentre {
  display: flex;
  align-items: center;
  height: 100%;
}

.ban_science{
  margin-left: 0.63rem;
}

.science_zh span{
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.science_en span{
  display: block;
  font-size: 0.64rem;
  font-weight: 400;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.progress {
  height: 10px;
  width: 0%;
  background-color: #000022;
  margin: 30px 40%;
  border-radius: 10px;
  animation: move 5s 1;
}

@keyframes move {
  form {
    width: 0%;
  }
  to {
    width: 20%;
  }
}

.model {
  background-color: transparent;
  /* z-index: -3;
  position: relative; */
}