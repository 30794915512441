.content1 {
  padding: 0.77rem 0.63rem;
  text-align: center;
  box-sizing: border-box;
}

.head {
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: relative;
}

.logo {
  width: 3.13rem;
  position: absolute;
  z-index: 99;
  object-fit: contain;
}

.but {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 5px;
}

.nav {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 30% 0.64rem 0 0;
}

.nav_child {
  width: 3rem;
  border: 2px solid transparent;
  border-radius: 30px;
  margin-bottom: 0.4rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #000022, #000022),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child:active {
  background-image: linear-gradient(90deg, #2b3c6e, #2b3c6e),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child_title_zh {
  line-height: 1.6;
  display: block;
  font-size: 0.27rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.nav_child_title_en {
  display: block;
  font-size: 0.19rem;
  font-weight: 400;
  color: rgba(255, 254, 254, 1);
}

.banner {
  display: flex;
  position: relative;
  align-items: center;
}

.banner canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: -2;
}

.banner_left {
  width: 100%;
  position: absolute;
  bottom: 10%;
  left: 0;
}

.banner_left img {
  width: 90%;
}

.banner_right {
  position: absolute;
  right: 18%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.banner_zh {
  display: block;
  font-size: 1.36rem;
  font-weight: bold;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner_en {
  display: block;
  font-size: 0.61rem;
  font-weight: 400;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2;
}

.shadow {
  background-color: #000022;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9 !important;
  width: 100%;
  z-index: 3;
}

.hint {
  background-color: #383854;
  border-radius: 20px;
  width: 0.47rem;
  height: 0.89rem;
  margin: 0 auto;
  display: flex;
  padding-left: 0.23rem;
  padding-top: 0.2rem;
}

.hint_hang {
  height: 1px;
  width: 2px;
  background-color: #fff;
  animation: move 2s infinite;
}

@keyframes move {
  from {
    height: 0%;
  }
  to {
    height: 70%;
  }
}
