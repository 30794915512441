.wrapper {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  display: flex;
}

.wrapper div {
  position: absolute;
  opacity: 0;  
}

.wrapper div.grow {
  -webkit-animation: grow 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation: grow 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: grow 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

div.grow  {
  width: 12px;
  height: 12px;
  background: rgb(255,255,255,.4);
}

div.grow img {
  margin-left: 50%;
  margin-top: -10px;
  width: 10px;
  transform: translate(-50%,0);
}

@keyframes grow {
  0% {transform: scale(1);opacity: 0.4;}
  30% {transform: scale(2);opacity: 0.4;}
  100% {transform: scale(1);}
}