.close {
  padding: 0.73rem 0.61rem 1.08rem;
  display: flex;
  justify-content: flex-end;
}

.close img {
  width: 0.51rem;
  height: 0.64rem;
}

.pro_con {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  position: absolute;
  width: 33%;
  height: 64%;
  top: 20%;
}

.mark {
  position: relative;
  margin-right: 0.43rem;
  margin-top: 0.2rem;
}

.mark img {
  width: 0.88rem;
  height: 0.88rem;
}

.mark span {
  position: absolute;
  left: 0.05rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(252, 252, 252, 1);
}

.content_left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.1rem;
}

.content {
  margin: 0 0 0 20%;
  display: flex;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
}

.column_zh {
  font-size: 0.56rem;
  font-weight: 500;
  word-spacing: normal;
  color: rgba(0, 0, 34, 1);
}

.column_en {
  font-size: 0.35rem;
  font-weight: 400;
  word-break: normal;
  color: rgba(33, 33, 48, 1);
}
