.content4 {
  padding-bottom: 0.64rem;
  position: relative;
}

.partner {
  text-align: center;
  font-size: 0.48rem;
  font-weight: 400;
  color: rgba(254, 254, 254, 1);
  padding: 0.48rem 0 0;
}

@media only screen and (max-width: 828px) {
  .partner {
    text-align: left !important;
    font-size: 0.48rem;
    font-weight: 400;
    color: rgba(254, 254, 254, 1);
    padding: 0.48rem 0 !important;
    margin: 0 0.43rem;
  }

  .brand {
    width: 100% !important;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: flex-start !important;
  }

  .brand_time {
    margin: 0 0.43rem 0.43rem !important;
  }
}

.brand {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.43rem;
  margin: 0 auto;
}

.brand_time {
  margin: 1.4rem auto;
}

.brand_time img {
  width: 1.6rem;
}

@media only screen and (max-width: 667px) {
  .brand_time img {
    width: 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .foot {
    border-top: 1px solid #1f1b41;
    display: flex;
    padding: 0rem 0.43rem 0 !important;
  }
}

.foot {
  border-top: 1px solid #1f1b41;
  display: flex;
  padding: 0rem 2.13rem 0;
}

.introduce {
  width: 75%;
}

.code {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.code img {
  height: 1.73rem;
  width: 1.73rem;
  margin-bottom: 0.8rem;
  border-radius: 5px;
}

.foot_content {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  margin-top: 0.2rem;
}

.foot_content_left {
  width: 35%;
}

@media only screen and (max-width: 992px) {
  .foot_content_right {
    width: 100% !important;
  }
}

.foot_content_right {
  width: 45%;
}

.foot_logo {
  width: 3.28rem;
  /* margin-top: 0.2rem; */
  object-fit: contain !important;
}

.button {
  height: 0.48rem;
  width: 3.51rem;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid #fff;
  box-sizing: border-box;
  margin-top: 0.57rem;
}

.button span {
  width: 50%;
  font-size: 0.19rem;
  font-weight: 400;
  color: rgba(0, 1, 31, 1);
  line-height: 0.48rem;
  border-radius: 30px;
}

.contact {
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
}

.address {
  background-color: #000022;
  border: 0;
  outline: none;
  flex: 1;
  color: #fff;
}

input::-webkit-input-placeholder {
  color: #ffffff;
}

input::-moz-placeholder {
  color: #ffffff;
}

input:-moz-placeholder {
  color: #ffffff;
}

input:-ms-input-placeholder {
  color: #ffffff;
}

.subscribe {
  width: 0.29rem;
  height: 0.21rem;
}

.details {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  flex-wrap: wrap;
  margin-top: 0.48rem;
}

.details_left {
  width: 35%;
}

@media only screen and (max-width: 992px) {
  .details_left {
    width: 75%;
  }

  .details_right {
    width: 75%;
  }
}

.details_childer {
  display: flex;
  align-items: center;
}

.details_childer span {
  font-size: 0.27rem;
  font-weight: 400;
  color: rgba(153, 153, 165, 1);
  line-height: 0.48rem;
}

.details_childer img {
  width: 20px;
  margin-right: 5px;
}

.foot_copyright {
  font-size: 0.19rem;
  font-weight: 400;
  color: rgba(146, 146, 157, 1);
  margin-top: 0.1rem;
}

.foot_copyright a {
  color: rgba(146, 146, 157, 1);
}

.foot_copyright a:hover {
  color: #fff;
}

.row {
  margin: 0 !important;
}