.pcAllPhone {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex; 
  flex-direction: column;
}

.pcAllPhoneImg{
  min-width: 63px;
  position: relative;
  margin-top: 10px;
}

.pcWorkPhoneContentOne {
  background-image: linear-gradient(rgba(234, 114, 165), rgba(184, 79, 92));
  overflow: hidden;
  border-radius: 7%;
  height: 100%;
  position: relative;
}

.pcwPContentOneImg {
  display: flex;
  justify-content: space-between;
}

.pcwPContentOneTextTop {
  display: flex;
  flex-direction: column;
  color: #666666;
  margin-top: 15%;
}

.pcwPContentOneTextTop span:nth-child(1){
  font-size: 28px;
}

.pcwPContentOneTextTop span:nth-child(2) {
  color: #666666;
  font-size: 20px;
}

.pcwPContentOneTextBotton {
  display: flex;
  flex-direction: column;
  color: #eee;
  margin-top: 5%;
  font-size: 13px;
}

.pcWorkPhonePrev {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
}

.pcWorkPhoneNext {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 99;
}

.pcwPContentOneTextButton {
  width: 50%;
  border-radius: 10%;
  background: rgba(255, 101, 91);
  border: 0;
  color: white;
  display: flex;
  margin: 10% auto;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.pcwPContentOneTextButton > div {
  display: flex;
  justify-content: center;
}

.pcPhoneDownload {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  min-width: 22px;
}

.pcPhoneDownload > div {
  display: flex;
  align-items: center;
  position: absolute;
  transition: opacity 4s;
}

.pcPhoneDownload div img {
  min-height: 16px;
  min-width: 16px;
}

.PhoneContentButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pcwpImgOne {
  width: 50%;
  margin: 9% auto auto 50%;
  animation: ImgOne 8s infinite both;
  transform: translateX(-50%) translateZ(50px);
  backface-visibility: hidden;
}

@keyframes ImgOne {
  40% {transform: translateX(-50%) translateZ(50px) rotateY(0deg);}
  50% {transform: translateX(-50%) translateZ(50px) rotateY(180deg);}
  90% {transform: translateX(-50%) translateZ(50px) rotateY(180deg);}
  100% {transform: translateX(-50%) translateZ(50px) rotateY(360deg);}
}

.pcwpImgTwo {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 9%;
  transform: translateZ(50px) rotateY(180deg) translate(-50%, 0);
  animation: ImgTwo 8s infinite both;
  backface-visibility: hidden;
}

@keyframes ImgTwo {
  40% {transform: translateX(-50%) translateZ(50px) rotateY(180deg);}
  50% {transform: translateX(-50%) translateZ(50px) rotateY(360deg);}
  90% {transform: translateX(-50%) translateZ(50px) rotateY(360deg);}
  100% {transform: translateX(-50%) translateZ(50px) rotateY(180deg);}
}

.backgroundTextOne {
  position: absolute;
  left: 15%;
}

.backgroundTextTwo {
  left: 75%;
  position: absolute;
}

.backgroundTextOne span {
  display: inline-block;
  text-shadow: 0 0 0 #666666;
  animation: smokyTwo 6s 2s infinite;
}

.text {
  color: transparent;
  backface-visibility: hidden;
  top: 50%;
  width: 100%;
  position: relative;
}

.backgroundTextOne span:nth-child(even) {
  animation-name: smoky-mirrorTwo;
}

.backgroundTextTwo span {
  display: inline-block;
  text-shadow: 0 0 0 #666666;
  animation: smoky 6s 2s infinite;
}

.backgroundTextTwo span:nth-child(even) {
  animation-name: smoky-mirror;
}

@keyframes smoky {
  100% {
    transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg) scale(1.5);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

@keyframes smoky-mirror {
  100% {
    transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg) scale(2);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

@keyframes smokyTwo {
  100% {
    transform: translate3d(-15rem, -8rem, 0) rotate(-40deg) skewX(70deg) scale(1.5);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

@keyframes smoky-mirrorTwo {
  100% { 
    transform: translate3d(-18rem, -8rem, 0) rotate(-40deg) skewX(-70deg) scale(2);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

.hide {
  display: none;
}

.show {
  display: hidden;
}

.pcModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.pcModal img {
  width: 150px;
}