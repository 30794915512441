.footer {
  z-index: -1;
  --footer-background: rgba(223, 82, 76);
  display: grid;
  position: relative;
  grid-area: footer;
  
}

.footer .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: var(--footer-background);
  -webkit-filter: url("#blob");
  filter: url("#blob");
}

.footer .bubbles .bubble {
  position: absolute;
  left: var(--position, 50%);
  background: var(--footer-background);
  border-radius: 100%;
  -webkit-animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s), bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s), bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.footer .content {
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4rem;
  background: var(--footer-background);
}

.footer .content a,.footer .content p {
  color: #F5F7FA;
  text-decoration: none;
}

.footer .content b {
  color: white;
}

.footer .content p {
  margin: 0;
  font-size: .75rem;
}

.footer .content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer .content > div > div {
  margin: 0.25rem 0;
}

.footer .content > div > div > * {
  margin-right: .5rem;
}

.footer .content > div .image {
  align-self: center;
  width: 3rem;
  height: 3rem;
  margin: 0.25rem 0;
  background-size: cover;
  background-position: center;
}

@-webkit-keyframes bubble-size {
  0%, 75% {width: var(--size, 3rem);height: var(--size, 3rem);}
  100% {
    width: 0rem;
    height: 0rem;
  }
}

@keyframes bubble-size {
  0%, 75% {width: var(--size, 3rem);height: var(--size, 3rem);}
  100% {width: 0rem;height: 0rem;}
}

@-webkit-keyframes bubble-move {
  0% {bottom: -1rem;}
  100% {bottom: var(--distance, 5rem);}
}

@keyframes bubble-move {
  0% {bottom: -1rem;}
  100% {bottom: var(--distance, 5rem);}
}