*{
    margin: 0;
    padding: 0;
    list-style: none;
}

a{
    color: #fff;
}

.mbh-root-container {
    background-color: #1A237E;
    width: 100%;
    height: 150vh;
    overflow-x: hidden;
}

.mbh-bgimg-ver {
    width: 100%;
    position: absolute;
    top: 0;
    pointer-events:none;
}

.mbh-invitation-text {
    position: absolute;
    left: 8%;
    font-size: 1.5rem;
    font-weight: 1000;
    color: rgba(186, 26, 253, 0.8);
    letter-spacing: 0.4rem;
}

.mbh-title-layer {
    position: absolute;
    top:20%;
    left: 8%;
    color:#fff;
}

.mbh-title-num {
    font-size:0.4rem;
}

.mbh-title-chinese {
    font-size: 0.25rem;
}

.mbh-title-english {
    font-size: 0.25rem;
}

.mbh-slogan-layer {
    position: absolute;
    top:40%;
    left: 8%;
    z-index: 2;
    font-size: 0.7rem;
}

.mbh-slogan-front {
    color: rgba(186,26,253,.9);
    position: relative;
}

.mbh-slogan-behind {
    margin-top: -1.04rem;
    color: rgba(0,0,0,.7);
}

.mbh-start-date {
    position: absolute;
    top:55%;
    left: 8%;
    padding: 0.0001rem 0.1296rem 0.0556rem;
    font-size: 0.3rem;
    color: #fff;
    z-index: 2;
    background-color: rgba(186, 26, 253, 100);
}

.mbh-start-text {
    position: absolute;
    top:62%;
    left: 8%;
    font-size: 0.25rem;
    color: #fff;
    z-index: 2;
}

.mbh-hot-title {
    position: absolute;
    top: 20%;
    right: 8%;
    color: #ffffff;
    z-index: 2;
    font-size: 1rem;
}

.mbh-hot-content {
    position: absolute;
    top: 35%;
    right: 8%;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.8);
    z-index: 2;
}

.mbh-express-text {
    position: absolute;
    font-size: 0.2rem;
    width: 68vw;
    left: 8%;
    top: 70%;
}

.mbh-qrcode-img {
    position: absolute;
    height: 16vw;
    top:60%;
    right: 8%;
    z-index: 2;
}

.mbh-qrcode-text {
    position: absolute;
    top: 85%;
    right: 8%;
    color: #fff;
}

.mbh-qrcode-text2 {
    position: absolute;
    top: 88%;
    right: 8%;
    color: #fff;
}

footer.mbh-footer-text{
    position: absolute;
    bottom: 2%;
    left: 8%;
    color:rgba(255,255,255,0.8);
}
