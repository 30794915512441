.head {
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: relative;
  z-index: 99;
}

.logo {
  width: 3.13rem;
  height: 0.8rem;
}

.nav {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.nav_child {
  width: 3rem;
  border: 2px solid transparent;
  border-radius: 30px;
  margin-bottom: 0.4rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #000022, #000022),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child:active {
  background-image: linear-gradient(90deg, #2b3c6e, #2b3c6e),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_activt {
  background-image: linear-gradient(90deg, #2b3c6e, #2b3c6e),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child_title_zh {
  line-height: 1.6;
  display: block;
  font-size: 0.27rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.nav_child_title_en {
  display: block;
  font-size: 0.19rem;
  font-weight: 400;
  color: rgba(255, 254, 254, 1);
}