.pcScpageBanner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.myCan {
  width: 100%;
  position: absolute;
  z-index: -1;
  height:100%
}

.myCanImg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.myCanImgShow {
  position: absolute;
  left: 65%;
  top: 30%;
}

.myCanLanren {
  background: rgba(0,0,0,0);
  position: absolute;
  left: 65%;
}

.myDiv {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.bannerCanvas , .BannerCanvasTwo , .BannerCanvasThree {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.contentLeft {
  animation: leftRight 5s infinite;
  transform: translate(0,0);
}

@keyframes leftRight {
  50%{transform: translate(25px,0);}
  100%{transform: (0,0);}
}

.contentRight {
  animation: rightLeft 5s infinite;
  transform: translate(-25px,0);
}

@keyframes rightLeft {
  50%{transform: translate(0,0);}
  100%{transform: translate(-25px,0);}
}

.contentBottom {
  position: absolute;
  left: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 1;
  transform: translate(-50%,0);
  cursor: pointer;
}

.contentBottomImg{
  transform: rotateX(180deg);
}

.bannerContentOne {
  padding: 2.6% 0 0 25.83%;
  display: flex;
  justify-content: space-between;
}

.bannerOneText {
  color: aliceblue;
  display: flex;
}

.bannerOneImg {
  padding: 0 20.83% 0 0;
  cursor: pointer;
}

.Hand_logo{
  width: 15%;
}

.bannerContentThree {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pcScpageBanner:hover .contentThreeLeft {
  opacity: 1;
  cursor: pointer;
}

.pcScpageBanner:hover .contentThreeRight {
  opacity: 1;
  cursor: pointer;
}

@keyframes smoky {
  0%{opacity: 0;transform: scale3d(.3, .3, .3);}
  20%{transform: scale3d(1.1, 1.1, 1.1);}
  40%{transform: scale3d(.9, .9, .9);}
  60%{opacity: 1;transform: scale3d(1.03, 1.03, 1.03);}
  80%{transform: scale3d(.97, .97, .97);}
  100%{opacity: 1;transform: scale3d(1, 1, 1);}
}

.bannerContentFive {
  margin: 0% 0 0 30.48%;
  cursor: pointer;
}

.contentFiveinnerCircle {
  color: aliceblue;
  text-align: center;
  margin: 0 auto;
}
.contentFiveinnerCircle div {
  position: absolute;
  transition: opacity 3s;
}

.pcScoageWork {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
}

.pcWorkTop {
  padding: 5% 0;
}

.workOne {
  color: #666666;
}

.workTwo {
  color: #999999;
  padding: 0.5% 0 0 0;
}

.workThree {
  display: flex;
  justify-content: center;
}

.WorkCanvas {
  z-index: -4;
  position: relative;
  width: 100%;
  height: 100%;
}

.WorkCanvasTwo {
  width: 100%;
  position: absolute;
  z-index: -4;
}

.pcPhoneCanvas {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -99;
  min-height: 487px;
}

.pcWorkText {
  z-index: -99;
  top: 0;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.workcellPhone {
  margin: 2% 0 0 0;
  position: relative;
}

.pcPhoneContent{
  width: 15.3%;
  height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow: hidden;
  background-image: linear-gradient(rgba(234,114,165),rgba(184,79,92));
  border-radius: 5%;
  min-height: 273px;
  min-width: 126px;
}

.pcScoageChoose {
  width: 100%;
  position: absolute;
}

.ChooseCanvas {
  width: 100%;
  height: 100%;
}

.pcChoose {
  position: relative;
  width: 100%;
  height: 100%;
  
}

.pcChooseOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pcChooseOneOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6%;
}

.pcChooseOneTextOne {
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
}

.pcChooseOneTextTwo {
  color: #eee;
  padding: 0.5% 0 2% 0;
}

.pcChooseOneTwo {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
  position: relative;
  white-space: nowrap;
}

.pcChooseCircleOne {
  position: absolute;
  top: 0;
  animation: CircleLittle 20s both infinite;
}

.pcChooseOneTwoOneLeft , .pcChooseOneTwoOneRight {
  display: flex;
  width: 10%;
  align-items: center;
}

.pcCircleOne {
  position: relative;
}

.pcChooseCircle {
  position: absolute;
  top: 0;
  animation: CircleLittleTwo 20s both infinite;
}

.pcChooseCircleLittle {
  background: #eee;
  border-radius: 50%;
  position: absolute;
}

.pcChooseContent , .pcChooseContentRight {
  letter-spacing: 1px;
  color: #fff;
  margin: 0 0 0 2%;
  width: 80%;
}

.pcChooseContentTwoRight {
  letter-spacing: 1px;
  color: #fff;
  margin: 0 0 0 2%;
}

.pcChooseOneThree {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  position: absolute;
  white-space: nowrap;
}

.pcChooseCircleThree {
  position: absolute;
  top: 0;
  animation: CircleLittleThree 20s both infinite;
}

.ChooseCircleFour {
  position: absolute;
  top: 0;
  animation: CircleLittleFour 20s both infinite;
}

.pcChooseOneTwoTwoLeft {
  display: flex;
  width: 10%;
  align-items: center;
}

.pcChooseContentTwo {
  letter-spacing: 1px;
  color: #fff;
  margin: 0 0 0 2%;
}

.pcChooseOneTwoTwoRight {
  display: flex;
  width: 10%;
  align-items: center;
}

.pcChooseCanvas {
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
}

.pcChooseCanvasImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-25%,5%);
}

.pcScoageType {
  width: 100%;
  text-align: center;
  padding: 4.26% 0 0 0;
}

.typeOne {
  color: rgba(0, 0, 0, 0.8);
}

.typeTwo {
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5% 0 0 0;
}

.pcScoage_carousel {
  width: 100%;
}

.pcCarouselGif {
  position: absolute;
  z-index: -99;
  top: 22.32%;
  left: 38.46%;
  width: 100%;
}

.pcCarouselGif ul {
  width:0;
}

.closeEnd {
  overflow: hidden;
}

.pcScoage_carousel_content {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  width: 60%;
}

.carouselLeft {
  opacity: 0;
  transition: opacity 0.5s;
}

.carouselLeftImg {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 30%;
  left: 10%;
}

.pcCarousel_center_content {
  width: 80%;
  display: flex;
  justify-content: space-between;
}


.carouselRight {
  opacity: 0;
  transition: opacity 0.5s;
}

.carouselRightImg {
  right: 13.38%;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  left: 80%;
  top: 25%;
}

.within_left {
  display: flex;
  width: 60%;
  margin-right: 10%;
}

.within_right {
  width: 30%;
}

.within_left_textbox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.within_right_textbox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.left_text {
  border: 1px solid red;
  display: flex;
  transform: translate(-14%, -1%);
}

.right_text {
  border: 2px solid salmon;
}

.within_leftImg {
  border: 2px solid salmon;
  transform: translate(30%, 16%);
}

.within_right_img {
  border: 2px solid white;
  background: #000;
  transform: translate(-40%, 15%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pcCarouselAll:hover .carouselRight {
  opacity: 1;
}

.pcCarouselAll:hover .carouselLeft {
  opacity: 1;
}

.pcimmigration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5% 0 0 0;
}

.pcimmigration div {
  border-radius: 50%;
  transform: rotate(45deg);
}

.pcCooperation {
  width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  position: relative;

}

.pcCooperationDiv {
  color: aliceblue;
  letter-spacing: 1px;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 21%;
  position: absolute;
  z-index: 10;
}

.friendLeft {
  border: 1px solid #eee;
  width: 2vw;
  border-right: 0;
  height: 5vh;
  animation: friendHeight 6s both infinite;
  margin: auto 0;
}

.friendCenter {
  cursor: pointer;
  height: 5vh;
  line-height: 5vh;
}

.friendRight {
  border: 1px solid #eee;
  width: 2vw;
  border-left: 0;
  height: 5vh;
  margin: auto 0;
  animation: friendHeight 6s both infinite;
}

.pcCooperationFriend {
  z-index: -99;
}

@keyframes friendHeight {
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

.pcCarouselAll {
  width: 100%;
  height: 100%;
  display: flex;
}

.swiper-pagination-customs {
  background: red;
}

.addressLoging {
  cursor: pointer;
}

.as {
  width: 100%;
  margin: 1% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineOne {
  border: 1px solid #ef605a;
  background: #ef605a;
}

.lineTwo {
  border: 1px solid #ef605a;
  margin-left: 4px;
  background: #ef605a;
}

.lineThree {
  border: 1px solid #ef605a;
  margin-left: 4px;
  background: #ef605a;
}

.lineChooseOne {
  border: 1px solid #eee;
  background: #eee;
}

.lineChooseTwo {
  border: 1px solid #eee;
  margin-left: 4px;
  background: #eee;
}

.lineChooseThree {
  border: 1px solid #eee;
  margin-left: 4px;
  background: #eee;
}

@keyframes CircleLittle {
  0%{transform: rotate(0deg);opacity: 1;}
  25%{transform: rotate(360deg);opacity: 1;}
  26%{opacity: 0;}
  100%{opacity: 0;}
}

@keyframes CircleLittleTwo {
  0%{opacity: 0;}
  25%{transform: rotate(0deg);opacity: 0;}
  26%{opacity: 1;}
  50%{transform: rotate(360deg);opacity:1}
  51%{opacity: 0;}
  100%{opacity: 0;}
}

@keyframes CircleLittleThree {
  0%{opacity: 0;}
  50%{opacity: 0;}
  51%{opacity: 1;transform: rotate(0deg);}
  75%{transform: rotate(360deg);opacity: 1;}
  76%{opacity: 0;}
  100%{opacity: 0;}
}

@keyframes CircleLittleFour {
  0%{opacity: 0;}
  75%{opacity: 0;}
  76%{opacity: 1;transform: rotate(0deg);}
  100%{opacity: 1;transform: rotate(360deg);}
}

.contentTopLeft {
  opacity: 0;
  animation: contentTopLeft 20s both infinite;
}

.contentTopLeft span {
  animation: contentTopLeft 20s var(--s) both infinite;
}

@keyframes contentTopLeft {
  0%{opacity: 1;}
  25%{opacity: 1;}
  30%{opacity: 0;}
  100%{opacity: 0;}
}


.contentTopRight {
  opacity: 0;
  animation: contentTopRight 20s infinite;
}

.contentTopRight span {
  animation: contentTopRight 20s var(--s) infinite;
}

@keyframes contentTopRight {
  0%{opacity: 0;}
  25%{opacity: 0;}
  26%{opacity: 1;}
  50%{opacity: 1;}
  55%{opacity: 0;}
  75%{opacity: 0;}
  100%{opacity: 0;}
}

.contentBottomLeft {
  opacity: 0;
  animation: contentBottomLeft 20s infinite;
}

.contentBottomLeft span {
  animation: contentBottomLeft 20s var(--s) infinite;
}

@keyframes contentBottomLeft {
  0%{opacity: 0;}
  50%{opacity: 0;}
  51%{opacity: 1;}
  75%{opacity: 1;}
  80%{opacity: 0;}
  100%{opacity: 0;}
}

.contentBottomRight {
  opacity: 0;
  animation: contentBottomRight 20s infinite;
}

.contentBottomRight span{
  animation: contentBottomRight 20s var(--s)  infinite;
}

@keyframes contentBottomRight {
  6%{opacity: 0;}
  50%{opacity: 0;}
  51%{opacity: 0;}
  75%{opacity: 0;}
  76%{opacity: 1;}
  100%{opacity: 1;}
}

.header {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  min-height: 487px;
}

.waves {
  position:relative;
  width: 100%;
}

.parallax{
  position: absolute;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)  infinite;
}

@keyframes move-forever {
  0% {transform: translate3d(-90px,0,0);}
  100% { transform: translate3d(85px,0,0);}
}

.canvasPcOne {
  width: 100%;
  height: 100%;
}

.canvanPcTwo {
  width: 100%;
  height: 100%;
  z-index: -4;
}