.content1 {
  padding: 0.77rem 0.63rem;
  text-align: center;
  box-sizing: border-box;
}

.head {
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: relative;
  z-index: 99;
}

.logo {
  width: 3.13rem;
}

.nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nav_child {
  width: 3rem;
  border: 2px solid transparent;
  border-radius: 30px;
  margin-bottom: 0.4rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #000022, #000022),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child:active {
  background-image: linear-gradient(90deg, #2b3c6e, #2b3c6e),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_activt {
  background-image: linear-gradient(90deg, #2b3c6e, #2b3c6e),
    linear-gradient(90deg, rgba(250, 236, 130, 1), #02c4d4);
}

.nav_child_title_zh {
  line-height: 1.6;
  display: block;
  font-size: 0.27rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.nav_child_title_en {
  display: block;
  font-size: 0.19rem;
  font-weight: 400;
  color: rgba(255, 254, 254, 1);
}

.banner {
  display: flex;
  align-items: center;
  position: relative;
}

.banner_left {
  width: 100%;
}

.banner_left img {
  width: 6.57rem;
}

.banner_right {
  position: absolute;
  width: 100%;
  left: 0;
}

.banner_zh {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
}

.banner_en {
  display: block;
  font-size: 0.96rem;
  font-weight: 500;
  color: rgba(245, 245, 245, 1);
}

.product {
  position: absolute;
  bottom: 30px;
  width: 3.6rem;
  left: 50%;
  margin-left: -1.8rem;
  border: 2px solid #fff;
  border-radius: 30px;
}

.product_zh {
  display: block;
  font-size: 0.4rem;
  font-weight: 400;
  color: rgba(245, 245, 245, 1);
}

.product_en {
  display: block;
  font-size: 0.24rem;
  font-weight: 400;
  color: rgba(245, 245, 245, 1);
}

.product:active {
  background-color: rgba(254, 254, 254, 0.2);
  border: 0;
}
