.content1 {
  padding: 0.77rem 0.63rem;
  text-align: center;
  box-sizing: border-box;
  background: url(../img/img_earth.jpg) no-repeat bottom;
  background-size: 100%;
  background-color: #000022;
}

.banner {
  display: flex;
  align-items: center;
}

.banner_right {
  margin: 0 auto;
}

.banner_zh {
  display: block;
  font-size: 1.23rem;
  font-weight: bold;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.codeimg {
  transform: rotateX(40deg);
  width: 80%;
  position: absolute;
  left: 10%;
  bottom: 10%;
}

.banner_en {
  display: block;
  font-size: 0.64rem;
  font-weight: 400;
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  background-image: -webkit-linear-gradient(left, #a4e194, #02c4d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
